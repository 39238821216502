@import "../../../less/variables.less";

.sequence-theme {
    /* this container is just to ensure the background color stretches all the way across on bigger screens */
    background: #D9D9D9;
}

#sequence {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1280px;
    background-color: #D9D9D9;
    background-position: 50% 100%;
    color: white;
    font-size: 0.625em;
    margin: 0 auto;
    position: relative;
    height: 300px;
    
    @media only screen and (max-width : 768px) { 
       
        height: 200px;
        
        h2 {
            font-size:1.3em !important;
            margin-top:0;
        }
        
        h3 {
            font-size:1.1em !important;
            margin-top:0;
        }
        
    }
    
     @media only screen and (max-width : 1024px) { 
       
        height: 200px;
        
        h2 {
            font-size:1.4em !important;
            margin-top:0;
        }
        
        h3 {
            font-size:1.2em !important;
            margin-top:0;
        }
        
    }

    .sequence-canvas {
        height: 100%;
        width: 100%;

        li {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            top: -50%;

            img {
                height: 100%;
            }

            * {
                position: absolute;
                /* only cause the left and opacity properties to transition */
                -webkit-transition-property: left, opacity;
                -moz-transition-property: left, opacity;
                -ms-transition-property: left, opacity;
                -o-transition-property: left, opacity;
                transition-property: left, opacity;
            }
        }
    }

    .title {
        font-size: 2.2em;
        background: #a1a1a1;
        background: rgba(0, 0, 0, 0.3);
        bottom: 0;
        left: 100%;
        padding: 4%;
        bottom: -50%;
        width: 100%;
        z-index: 10;
    }

    .animate-in .title {
        left: -10%;
    }

    .animate-out .title {
        left: -100%;
    }

    .subtitle {
        display: none;
    }

    .cta {
        display: none;
    }

    .slide {
        // left: 50%;
        bottom: -50%;
        opacity: 0;
        position: relative;
        height: 100%;
        left: 100%;
        // min-width: 40%;
        /*prevents the slide from shrinking when the browser is resized*/
        width: 100%;
        margin-left: -12.5%;
    }

    .animate-in .slide {
        left: 0%;
        opacity: 1;
        -webkit-transition-duration: 1s;
        -moz-transition-duration: 1s;
        -ms-transition-duration: 1s;
        -o-transition-duration: 1s;
        transition-duration: 1s;
    }

    .animate-out .slide {
        left: 0%;
        opacity: 0;
        -webkit-transition-duration: 0.5s;
        -moz-transition-duration: 0.5s;
        -ms-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
    }
}

.sequence-next,
.sequence-prev {
    color: white;
    cursor: pointer;
    display: none;
    font-weight: bold;
    padding: 10px 15px;
    position: absolute;
    top: 50%;
    z-index: 1000;
    height: 75px;
    margin-top: -47.5px;
    position: absolute;
    opacity: 0.6;
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -ms-transition-duration: 1s;
    -o-transition-duration: 1s;
    transition-duration: 1s;

    &:hover {
        opacity: 1;
        -webkit-transition-duration: 1s;
        -moz-transition-duration: 1s;
        -ms-transition-duration: 1s;
        -o-transition-duration: 1s;
        transition-duration: 1s;
    }

    img {
        height: 100%;
        width: auto;
    }
}

.sequence-pause {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1000;
}

.sequence-paused {
    opacity: 0.3;
}

.sequence-prev {
    left: 3%;
}

.sequence-next {
    right: 3%;
}

#sequence-preloader {
    background: #d9d9d9;
}

h2 {
    color: white;
    font-family: "Sirin Stencil", serif;
    font-weight: bold;
    text-transform: uppercase;
}

h3 {
    font-family: "Ruluko", serif;
}

@media screen and (min-width: @screen-sm-min) {
    #sequence {
        .slide {
            left: 50%;
            margin-left: 0;
            width: auto;
        }

        .animate-in .slide {
            left: 10%;
        }

        .animate-out .slide {
            left: 50%;
        }

        .title {
            font-size: 3.4em;
            left: 65%;
            width: 35%;
            opacity: 0;
            bottom: 5%;
            z-index: 50;
            background: transparent;
            background: transparent;
            padding: 0;
        }

        .animate-in .title {
            left: 50%;
            opacity: 1;
            -webkit-transition-duration: 1.5s;
            -moz-transition-duration: 1.5s;
            -ms-transition-duration: 1.5s;
            -o-transition-duration: 1.5s;
            transition-duration: 1.5s;
        }

        .animate-out .title {
            left: 35%;
            opacity: 0;
            -webkit-transition-duration: 0.5s;
            -moz-transition-duration: 0.5s;
            -ms-transition-duration: 0.5s;
            -o-transition-duration: 0.5s;
            transition-duration: 0.5s;
        }

        .subtitle {
            display: block;
            color: black;
            font-size: 2em;
            left: 35%;
            width: 35%;
            opacity: 0;
            top: 96%;
            z-index: 30;
        }

        .animate-in .subtitle {
            left: 50%;
            opacity: 1;
            -webkit-transition-duration: 2s;
            -moz-transition-duration: 2s;
            -ms-transition-duration: 2s;
            -o-transition-duration: 2s;
            transition-duration: 2s;
        }

        .animate-out .subtitle {
            left: 65%;
            opacity: 0;
            -webkit-transition-duration: 0.5s;
            -moz-transition-duration: 0.5s;
            -ms-transition-duration: 0.5s;
            -o-transition-duration: 0.5s;
            transition-duration: 0.5s;
        }

        .cta {
            display: block;
            left: 45%;
            opacity: 0;
            top: 125%;
            z-index: 40;
        }

        .animate-in .cta {
            left: 60%;
            opacity: 1;
            -webkit-transition-duration: 2s;
            -moz-transition-duration: 2s;
            -ms-transition-duration: 2s;
            -o-transition-duration: 2s;
            transition-duration: 2s;
        }

        .animate-out .cta {
            left: 68%;
            opacity: 0;
            -webkit-transition-duration: 0.5s;
            -moz-transition-duration: 0.5s;
            -ms-transition-duration: 0.5s;
            -o-transition-duration: 0.5s;
            transition-duration: 0.5s;
        }
    }
}
